
<template>
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="is_loading">
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    <Skeleton></Skeleton>
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">


                <div class="row" v-for="(v,k) in 10" :key="k">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper" style="height:100%">
                      <a href=""
                        title="">
                          <Skeleton height="80%"></Skeleton>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a href=""
                          title="">
                            <Skeleton > </Skeleton>
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a href=""
                          title="">

                          <Skeleton height="100%"></Skeleton>
                      

                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              

              </div>
            </div>
          </div>
         
        </SkeletonTheme>
  </div>

   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else >
          <section class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                
                 <h2 class="titleh2n titleh2n_news" >
                   {{ $t("search.title",{keyword:keyword})}}
                </h2>

              </div>
            </div>
          </section>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">

  <div class="row"  v-for="(news,key) in paginationslice(my_data, 10, page)" :key="key">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper">
                      <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                        :title="news.name">
                        <img :alt="news.name" :src="myurl+''+news.picture" class="img-full lazy" >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                          :title="news.name" >
                          {{news.name}}
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                          :title="news.name" >

                           {{news.description}}
                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          {{news.modifiedTime | viewDate(news.createdTime)}}
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          {{news.viewer}} {{$t('news.view')}}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <hr />
               
               

              </div>


             
            </div>
            <div class="row">
              <div class="col-xl-12">
                  
                 <nav class="pagination-nav pdt-30" v-if="my_data.length>10">
                    

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                  </nav>




              </div>
            </div>
          </div>

        </div>



</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
     
      is_loading:true,
      page: 1,
      page_size:0,
      keyword:""

      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination
    
  },

  created(){
    this.keyword=this.$route.query.s;

    console.log(this.$route.query.s);
                // axios.get(window.my_api+"api/news/get-news?language="+this.$i18n.locale,
                //              {
                //                              headers: {
                //                                language: this.$i18n.locale
                //                              }
                //               })
                //           .then( (res)=>{
                //               this.is_loading=false;
                //               if(res.status==200){

                //                    this.my_data=res.data.data;
                                                        
                //               }else {
                //                 console.log("ERROR",res);
                //               }
                               

                //             });  
           
          
          this.page = 1;
       console.log(this.formSearch);
      axios
        .get(window.my_api + "api/news/get-cms-news",{
            headers: {
                        language: this.$i18n.locale
                    },
            params:{
              page:this.page,
              keyword:this.$route.query.s,
              catetegory:''

            }

        })
        .then((res) => {
          if (res.status == 200) {
             this.is_loading=false;

            this.my_data = this.main = res.data.data.records;
            this.page_size=res.data.data.total;
            this.my_data.sort((a, b) => a.orderBy - b.orderBy);
           
             
          } else {
            alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
          }
          this.is_loading = false;
        });
      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
      axios.get(window.my_api + "api/news/get-cms-news?page="+e, 
           { headers: {
                                 language: this.$i18n.locale
                               },
                       params:{
                         page:this.page,
                         keyword:this.$route.query.s,
                         catetegory:''
           
                       }}
      )
      .then((res) => {
        if (res.status == 200) {
          this.mydata = res.data.data.records;
          
          
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
      
    },
  },
   filters: {
        viewDate: function(string,createdtime) {
            if(string==null || string==undefined){
              return createdtime.substring(0,10);
            
            }          
            var today = new Date(string);

            var date = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();

            return date;

        }
      }
}
</script>
